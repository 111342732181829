.teaser-error {
  height: calc(100vh - 135px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.title {
  text-align: center;
  padding: 0 1rem;
}
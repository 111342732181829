.drawerWrapper {
  margin: 0 !important;
  padding: 0 12px;
}

.separator_mobile {
  height: 50px;
}

.separator_web {
  height: 50px;
}